import { useEffect } from "react";

import "../css/home.scss";
import ChatArea from "./ChatArea";

const Home = ({ user }) => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ideaId = urlParams.get("ideaId");
    const conversationId = urlParams.get("conversationId");
    user.setIdeaId(ideaId);
    user.setConversationId(conversationId);
  }, []);

  return (
    <div className="homeContainer">
      <ChatArea user={user} />
    </div>
  );
};

export default Home;
