// external
import { useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
// internal
import "../css/chatArea.scss";
import MessageBubble from "./MessageBubble";
import MessageInput from "./MessageInput";

const ObserveChatArea = observer(({ user }) => {
  const messagesRef = useRef(null);

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [user.messages]);

  return (
    <div className="caContainer">
      <div
        className="messagingContainer"
        ref={messagesRef}
        id="messagingContainer"
      >
        {user.messages.map((message) => {
          return (
            <MessageBubble
              user={user}
              type={message.type}
              message={message.message}
            />
          );
        })}
      </div>
      <MessageInput user={user} />
    </div>
  );
});

const ChatArea = ({ user }) => {
  return <ObserveChatArea user={user} />;
};

export default ChatArea;
