import axios from "axios";

const { REACT_APP_API_URL } = process.env;

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function HumanTurn(ideaId, conversationId, user_message) {
  try {
    let results = await axios({
      method: "post",
      url: `${REACT_APP_API_URL}/api/ai_agent/${ideaId}?conversation_id=${conversationId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        user_message,
      },
    });
    return results.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function SubmitMessage(user) {
  try {
    let inputMessage = user.inputMessage;
    user.setInputMessage("");
    // loading experience
    await delay(1000);
    let messages = Object.assign([], user.messages);
    messages.push({ type: "bot-loading", message: "...." });
    await user.setMessages(messages);
    // response experience
    let botResponse = await HumanTurn(user.ideaId, user.conversationId, inputMessage);
    let latestMessage = botResponse.latest_message.content;
    messages = Object.assign([], user.messages);
    messages.pop();
    messages.push({ type: "bot", message: latestMessage });
    await user.setMessages(messages);
  } catch (e) {
    return null;
  }
}
