// external
import { motion } from "framer-motion";
// internal
import UserAvatar from "../assets/UserAvatar.svg";
import BotAvatar from "../assets/BotAvatar.svg";
import "../css/messageBubble.scss";
import TypingEffect from "./TypingEffect";

const MessageBubble = ({ user, type, message }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: "-40%" }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="messageContainer"
    >
      <div className="messageLeft">
        {type === "user" ? (
          <img className="avatarImg" src={UserAvatar} alt="user avatar" />
        ) : (
          <img className="avatarImg" src={BotAvatar} alt="bot avatar" />
        )}
      </div>
      <div className="messageRight">
        <div className="messageBubble">
          {type === "user" ? (
            <span className="userText">{message}</span>
          ) : (
            <>
              {type === "bot-loading" ? (
                <span className="userText">{message}</span>
              ) : (
                <TypingEffect text={message} type={type} />
              )}
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default MessageBubble;
