import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import "../css/typingEffect.scss";
import remarkGfm from "remark-gfm";

function LinkRenderer(props) {
  console.log({ props });
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

const TypingEffect = ({ text, type }) => {
  const [displayText, setDisplayText] = useState("");
  let index = 0;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDisplayText(text.substring(0, index));
      index++;

      document.getElementById("messagingContainer").scrollTop =
        document.getElementById("messagingContainer").scrollHeight;

      if (index > text.length) {
        clearInterval(intervalId);
      }
    }, 10); // 100ms delay between each letter

    return () => clearInterval(intervalId);
  }, [text]);

  return (
    <>
      {type === "user" ? (
        <span className="userText">{displayText}</span>
      ) : (
        <div className="botText">
          <ReactMarkdown
            components={{ a: LinkRenderer }}
            remarkPlugins={[remarkGfm]}
          >
            {displayText}
          </ReactMarkdown>
        </div>
      )}
    </>
  );
};

export default TypingEffect;
