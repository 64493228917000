import React, { useRef, useEffect } from "react";

function ExpandingTextArea(props) {
  const textareaRef = useRef(null);

  const resize = () => {
    if (textareaRef.current) {
      // Reset the height to 'auto' before calculating the scrollHeight
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    // Call the resize function when the component mounts
    resize();
  }, []);

  return (
    <textarea
      id="messageInputField"
      {...props}
      ref={textareaRef}
      onInput={resize}
    />
  );
}

export default ExpandingTextArea;
