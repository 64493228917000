import { makeAutoObservable } from "mobx";

class User {
  constructor() {
    makeAutoObservable(this);
  }

  // session
  session = "";
  inputMessage = "";
  ideaId = "";
  conversationId = "";

  // conversation
  messages = [{ type: "bot", message: "How can I help you?" }];

  async setIdeaId(ideaId) {
    this.ideaId = ideaId;
  }

  async setConversationId(conversationId) {
    this.conversationId = conversationId;
  }

  async setMessages(messages) {
    this.messages = messages;
  }

  async setInputMessage(inputMessage) {
    this.inputMessage = inputMessage;
  }

  async setSession(session) {
    this.session = session;
  }
}

export default User;
