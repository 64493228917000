// external
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
//internal
import SubmitIcon from "../assets/SubmitIcon.svg";
import "../css/messageInput.scss";
import ExpandingTextArea from "./ExpandingTextArea";
import { SubmitMessage } from "../apis";

async function submitInput(user) {
  if (user.inputMessage.length > 0) {
    let messages = Object.assign([], user.messages);
    messages.push({ type: "user", message: user.inputMessage });
    await user.setMessages(messages);
    await SubmitMessage(user);
  }
}

const ObserveMessageInput = observer(({ user }) => {
  return (
    <div className="inputContainer">
      <div className="inputRow">
        <ExpandingTextArea
          type="text"
          className="inputField"
          placeholder="Enter a message"
          value={user.inputMessage}
          onChange={(event) => user.setInputMessage(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              submitInput(user);
              document.getElementById("messageInputField").style.height =
                "auto";
            }
          }}
          rows="1"
        />
        <div className="submitContainer">
          <motion.div
            className="submitIcon"
            whileTap={{
              scale: 0.9,
              rotate: -90,
            }}
            onClick={() => {
              submitInput(user);
              document.getElementById("messageInputField").style.height =
                "auto";
            }}
          >
            <img src={SubmitIcon} alt="submit" />
          </motion.div>
        </div>
      </div>
    </div>
  );
});

const MessageInput = ({ user }) => {
  return <ObserveMessageInput user={user} />;
};

export default MessageInput;
